import React from "react"
import { AlbumProps } from "./types"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import Image from "../Image"
import styles from "./Album.module.scss"
import animatedStyles from "../../theme/Animated.module.scss"
import { useAnimateOnce } from "../../theme/useAnimateOnce"

const Album: React.FC<AlbumProps> = ({
  children,
  band,
  name,
  published,
  image,
  className,
}) => {
  const [ref, visible] = useInView({
    rootMargin: "-10% 0px",
    triggerOnce: true,
  })

  const displayId = `${band}-${name}`
  const inView = useAnimateOnce(displayId, visible)

  return (
    <div className={classNames(styles.album, className)} ref={ref}>
      <div className={styles.imageContainer}>
        {image && (
          <Image
            fluid={image}
            className={styles.image}
            visible={visible}
            displayId={displayId}
          />
        )}
      </div>
      <div className={classNames(styles.info, inView)}>
        <div className={styles.release}>
          <h5 className={styles.band}>{band}</h5>
          <div className={classNames(styles.nameWrapper, inView)}>
            <h4 className={styles.albumName}>{name}</h4>
            <p className={styles.published}>{published}</p>
          </div>
        </div>
        <div className={classNames(styles.content, inView)}>{children}</div>
      </div>
    </div>
  )
}

export default Album
