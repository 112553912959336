import { useMemo } from "react"
import { filter, indexOf } from "lodash"

const elementSuffix = (el: Element): string | null => {
  if (el.id) {
    return `#${el.id}`
  }

  const siblings = filter<Node>(
    el.parentNode?.childNodes,
    child => child.nodeName === el.nodeName
  )

  return siblings.length > 1 ? `:eq(${indexOf<Node>(siblings, el)})` : null
}

const getDOMPath = (el?: Element | null, path: string[] = []): string[] => {
  if (!el) {
    return path
  }

  const nodeName = el.nodeName.toLowerCase()
  const suffix = elementSuffix(el)

  return getDOMPath(el.parentElement, [
    suffix ? `${nodeName}${suffix}` : nodeName,
    ...path,
  ])
}

export const useDOMPath = (target?: Element) =>
  useMemo(() => target && getDOMPath(target).join(" > "), [target])
