import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2007: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2007Query>(graphql`
    query Year2007 {
      hope: file(relativePath: { eq: "swallow-the-sun-hope.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      asleep: file(
        relativePath: { eq: "swallow-the-sun-dont-fall-asleep.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guided: file(relativePath: { eq: "ghost-brigade-guided-by-fire.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2007}>
      <YearOverview shows={149} countries={18} bands={3}>
        <p>
          We did 3 full tours with <strong>Swallow the Sun</strong>: first
          Europe with <strong>Moonsorrow</strong>, then 2 with{" "}
          <strong>Insomnium</strong> on the same bill&mdash;first supporting{" "}
          <strong>Katatonia</strong> in North America, then{" "}
          <strong>Amorphis</strong> in Europe. 94 other shows
          in Finland, rest of Europe and Russia between{" "}
          <strong>Swallow the Sun</strong>, <strong>Insomnium</strong> and{" "}
          <strong>Ghost Brigade</strong> makes 2007 my busiest year to date.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Swallow the Sun"
          name="Hope"
          published="Spinefarm Records"
          image={data.hope?.childImageSharp?.fluid}
        >
          <p>
            <i>Hope</i> was our first release on Spinefarm Records, and also the
            first time I worked together with Hannu Honkonen on keyboards. I
            recorded and engineered my keyboards at his production studio, while
            rest of the album was recorded and mixed at Suomenlinnan Studio
            (formerly Seawolf Studios) in Helsinki by Sami Kokko. This also
            marks the first time we worked with Jaani Peuhu, who’d eventually
            take on the keyboard duties in the band after my departure.
          </p>
          <p>
            In addition to arranging the keyboards, I wrote the music and lyrics
            for the song <i>The Empty Skies</i>.
          </p>
        </Album>
        <Quote author="Scott Alisoglu" source="Blabbermouth">
          <p>
            You’ll be amazed at the depth of these compositions. The vibe is
            gloom and doom from start to finish, but Swallow the Sun pieces
            everything together so splendidly that the arrangements seemingly
            become bigger each time through, the songs revealing previously
            undetected layers of dynamic song writing with each spin.
          </p>
        </Quote>
        <SideAlbum
          band="Swallow the Sun"
          name="Don’t Fall Asleep"
          published="Spinefarm Records"
          image={data.asleep?.childImageSharp?.fluid}
        >
          <p>
            <i>Don’t Fall Asleep</i> was released as a CD single with a cover
            version of <i>Timo Rautiainen &amp; Trio Niskalaukaus</i> song{" "}
            <i>Alavilla Mailla</i>.
          </p>
        </SideAlbum>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Ghost Brigade"
          name="Guided By Fire"
          published="Season of Mist"
          image={data.guided?.childImageSharp?.fluid}
        >
          <p>
            Wille from Jyväskylä-based <i>Ghost Brigade</i> sent me the demo of
            their demo at some point in 2006, and asked whether I’d be
            interested in doing some keyboards for them. My immediate response
            was &ldquo;do <i>not</i> send this to anyone else, I will do
            it&rdquo;&mdash;loved it from the first notes.
          </p>
          <p>
            The demo garnered the attention from record labels it deserved, and
            not soon after, our first album <i>Guided by Fire</i> was released
            by <i>Season of Mist</i>. In addition to arranging and performing
            the keyboards, I also played a bunch of shows live with them.{" "}
            <i>Guided by Fire</i> was recorded between Lutakko, Jyväskylä and
            Seawolf Studios, Helsinki by Mikko Poikolainen.
          </p>
        </Album>
        <Quote author="Alex Henderson" source="All Music">
          <p>
            Guided by Fire consistently thrives on melodies&mdash;dark, gloomy,
            brooding melodies. Melody is not an afterthought on Guided by Fire;
            it is a vital, essential part of what Ghost Brigade do on their very
            listenable and respectable debut.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2007
