import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2012: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2012Query>(graphql`
    query Year2012 {
      bird: file(
        relativePath: {
          eq: "swallow-the-sun-emerald-forest-and-the-blackbird.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2012} dark>
      <YearOverview shows={89} countries={18} dark>
        <p>
          We supported <strong>Paradise Lost</strong> and{" "}
          <strong>Moonspell</strong> on their European tours, and{" "}
          <strong>Kreator</strong> and <strong>Accept</strong> in North America
          with <strong>Swallow the Sun</strong>. In addition, we played a bunch
          of headlining and festival shows in Japan and Europe.
        </p>
      </YearOverview>
      <Page className={classNames(styles.center, styles.marginVertical5)}>
        <Album
          band="Swallow the Sun"
          name="Emerald Forest and the Blackbird"
          published="Spinefarm Records"
          image={data.bird?.childImageSharp?.fluid}
        >
          <p>
            Our fifth album, <i>Emerald Forest and the Blackbird</i>, might be
            my favourite of all of <i>Swallow the Sun</i> albums, the
            song writing was great and the production by Hiili Hiilesmaa was on
            point. I&mdash;once again&mdash;recorded and engineered all of the
            keyboard parts at Noisework Studio with Hannu Honkonen, and like on{" "}
            <i>New Moon</i>, did the layouts and art direction based on
            illustrations by Rami Mursula.
          </p>
        </Album>
        <Quote
          author="Ben Chipman"
          source="Metal Blast"
          style={{ marginBottom: 0 }}
        >
          <p>
            The all-star of this album is keyboardist Aleksi Munter, whose
            reserved atmospheric layers and melancholic piano lines give the
            album an extra layer of sadness and misery. His diverse sounds and
            keen ear for harmony and counterpoint elevate these recordings to
            the next level.
          </p>
        </Quote>
        <Quote author="Sam Roon" source="SkullsNBones" right>
          <p>
            Any aspiring keyboardist should buy this record and use it as a
            textbook on how to expertly execute the instrument in a heavy band.
            From the layers to the showcase moments, Aleksi’s performance is
            stellar.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2012
