import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2003: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2003Query>(graphql`
    query Year2003 {
      tmnc: file(
        relativePath: { eq: "swallow-the-sun-the-morning-never-came.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bloodSoaked: file(
        relativePath: { eq: "trollheims-grott-bloodsoaked-and-ill-fated.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2003}>
      <YearOverview shows={4} bands={2}>
        <p>
          We played our first shows with <strong>Swallow the Sun</strong> in
          Turku, Jyväskylä and Helsinki.
        </p>
      </YearOverview>
      <Page className={classNames(styles.center, styles.marginVertical5)}>
        <Album
          band="Swallow the Sun"
          name="The Morning Never Came"
          published="Firebox Records"
          image={data.tmnc?.childImageSharp?.fluid}
        >
          <p>
            I still remember how I felt listening to our first demo{" "}
            <i>Out of This Gloomy Light</i>&mdash;until I put that on, I wasn’t
            quite sure what we were doing, but on the very first listen I knew
            we were on to something special. Not too many months later, we
            recorded our first album, <i>The Morning Never Came</i>, which
            received critical acclaim in press all around the world, and I still
            consider it one of the best things I’ve ever worked on.
          </p>
          <p>
            I played and arranged all the keyboards on this one, recorded with
            Sami Kokko at Sam’s Workshop in Lutakko, Jyväskylä.
          </p>
        </Album>
        <Quote author="Dom Lawson" source="Kerrang">
          <h4>One of Doom Metal’s finest hours to date.</h4>
          <p>
            Not since My Dying Bride’s classic Turn Loose The Swans has there
            been such a flawless example of how sorrow can be harnessed to the
            heaviest of guitar tones, capped with virtuoso guttural growls and
            graced with an immaculate production patina, to such devastating
            effect. Every one of these eight tracks is an absolute fucking
            monster.
          </p>
        </Quote>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Trollheim’s Grott"
          name="Bloodsoaked and Ill-Fated"
          published="Woodcut Records"
          image={data.bloodSoaked?.childImageSharp?.fluid}
        >
          <p>
            I joined <i>Trollheim’s Grott</i> as a full-time member for their
            second album, <i>Bloodsoaked and Ill-fated</i>. The album took a
            very industrial turn from their more traditional first album with
            heavy emphasis on electronics.
          </p>
          <p>
            I wrote and produced the song <i>Addicted to Lethal Injections</i>,
            as well as, arranging and playing the keyboards. Recorded with Sam
            Jämsen at Studio Perkele in Kuopio.
          </p>
        </Album>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2003
