import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2016: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2016Query>(graphql`
    query Year2016 {
      winter: file(relativePath: { eq: "insomnium-winters-gate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2016}>
      <YearOverview shows={56} countries={12}>
        <p>
          In addition to playing headlining and festival shows in Europe, Egypt,
          Israel and Russia we supported <strong>Dark Tranquillity</strong> with{" "}
          <strong>Swallow the Sun</strong> on their North American tour.
        </p>
      </YearOverview>
      <Page className={styles.center}>
        <Album
          band="Insomnium"
          name="Winter’s Gate"
          published="Century Media"
          image={data.winter?.childImageSharp?.fluid}
        >
          <p>
            Under normal circumstances <i>Insomnium</i>’s seventh album,{" "}
            <i>Winter’s Gate</i>, being a concept album with one, 45-minute song
            on it, would’ve been intimidating, but as I had just finished{" "}
            <i>Swallow the Sun</i>’s <i>Songs from the North</i> it was more
            like business as usual. Once again, I recorded and engineered my
            keyboard arrangements at Hannu Honkonen’s place, this time at
            Scoring Helsinki. The album was mixed by Dan Swanö at Unisound in
            Örebro, Sweden.
          </p>
        </Album>
        <Quote author="Edwin McFee" source="Metal Hammer">
          <p>
            Opening with the sound of rolling waves and a delicate guitar part,
            the album is very much the proverbial ‘journey’ and prog and black
            metal influences pepper the melodic death-steeped opus. In addition
            to some ferocious riffs and duelling leads, there are Rick
            Wakeman&ndash;esque synths, howling choirs, morose pianos, folksy
            Spanish guitars and even a post-rock-meets-Tubular Bells section
            around the 13-minute mark making for a thrilling listen that’s every
            bit as epic as the original yarn it’s inspired by.
          </p>
        </Quote>
      </Page>
      <Page height={PageHeight.Third} />
    </TimelineSection>
  )
}

export default TimelineSection2016
