import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"

const TimelineSection2018: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2018Query>(graphql`
    query Year2018 {
      threat: file(relativePath: { eq: "become-a-threat-the-abyss.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2018}>
      <Page className={styles.center} height={PageHeight.Third}>
        <SideAlbum
          band="Become a Threat"
          name="The Abyss"
          published="Full House Records"
          image={data.threat?.childImageSharp?.fluid}
        >
          <p>
            I wrote and recorded the intro for the song <i>Terra Nova</i> for
            the Helsinki-based hardcore band.
          </p>
        </SideAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2018
