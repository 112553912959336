import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"
import classNames from "classnames"

const TimelineSection2000: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2000Query>(graphql`
    query Year2000 {
      pureSatanic: file(
        relativePath: { eq: "funeris-nocturnum-pure-satanic-blasphemy.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2000} dark>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Full}
      >
        <Album
          className={styles.marginVertical5}
          band="Funeris Nocturnum"
          name="Pure Satanic Blasphemy"
          published="Woodcut Records"
          image={data.pureSatanic?.childImageSharp?.fluid}
        >
          <p>
            I arranged and played the keyboards and co-wrote the title track of
            Funeris Nocturnum’s aptly named <i>Pure Satanic Blasphemy</i>. The
            album holds a special place in my heart, being the first one
            I&mdash;or anyone of us&mdash;worked on, and I still enjoy its
            breakneck intensity and no-fucks-given attitude.
          </p>
          <p>
            We wanted it to be fast, angry and blasphemous, and it is all that,
            and then some. Recorded with Arttu Sarvanne at Studio Watercastle,
            Jyväskylä.
          </p>
        </Album>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2000
