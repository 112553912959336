import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2014: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2014Query>(graphql`
    query Year2014 {
      shadows: file(
        relativePath: { eq: "insomnium-shadows-of-the-dying-sun.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2014}>
      <YearOverview shows={8} countries={6}>
        <p>
          We took a bit of a break from touring with <strong>Swallow the Sun</strong>,
          playing only selected festival shows in Mexico, the Caribbean Sea,
          Norway, China, Brazil and Finland.
        </p>
      </YearOverview>
      <Page className={styles.marginVertical5}>
        <Album
          band="Insomnium"
          name="Shadows of the Dying Sun"
          published="Century Media"
          image={data.shadows?.childImageSharp?.fluid}
        >
          <p>
            <i>Insomnium</i>’s sixth album&mdash;one of my favourites of
            theirs&mdash;was mixed by André Alvinzi at Fascination Street
            Studios in Örebro, Sweden. I recorded and engineered my keyboard
            arrangements, as usual, at Hannu Honkonen’s workshop, Noiseworks
            Studio.
          </p>
        </Album>
        <Quote author="Edwin McFee" source="Metal Hammer">
          <p>
            Shadows of the Dying Sun is an album that will reward the listener
            for years to come. Each composition is both sweeping and evocative,
            as dressed in elegant majesty as it is steeped in fearlessly
            executed heavy metal grandeur.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2014
