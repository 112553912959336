import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import { graphql, useStaticQuery } from "gatsby"
import YearOverview from "../YearOverview"

const TimelineSection2017: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2017Query>(graphql`
    query Year2017 {
      image: file(relativePath: { eq: "background-2017.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection
      year={2017}
      backgroundImage={data.image?.childImageSharp?.fluid}
      dark
    >
      <YearOverview height={PageHeight.Half} dark>
        <p>
          Performed Winter’s Gate in its entirety with{" "}
          <strong>Insomnium</strong> at Joensuu’s Ilosaarirock with Joensuu
          Symphonic Orchestra.
        </p>
      </YearOverview>
    </TimelineSection>
  )
}

export default TimelineSection2017
