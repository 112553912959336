import React from "react"
import Page, { PageHeight } from "../Page"
import styles from "./YearOverview.module.scss"
import classNames from "classnames"

type YearOverviewProps = {
  shows?: number
  countries?: number
  bands?: number
  height?: PageHeight
  dark?: boolean
}

const YearOverview: React.FC<YearOverviewProps> = ({
  children,
  shows,
  countries,
  bands,
  dark,
  height = PageHeight.Third,
}) => {
  return (
    <Page height={height}>
      <div className={classNames(styles.container, dark && styles.dark)}>
        <div>{children}</div>
        {(shows || countries || bands) && (
          <p className={styles.stats}>
            {shows && (
              <span>{`${shows} ${shows === 1 ? "show" : "shows"}`}</span>
            )}
            {countries && (
              <span>
                {`${countries} ${countries === 1 ? "country" : "countries"}`}
              </span>
            )}
            {bands && (
              <span>{`${bands} ${bands === 1 ? "band" : "bands"}`}</span>
            )}
          </p>
        )}
      </div>
    </Page>
  )
}

export default YearOverview
