import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2011: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2011Query>(graphql`
    query Year2011 {
      sorrow: file(relativePath: { eq: "insomnium-one-for-sorrow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      weather: file(relativePath: { eq: "insomnium-weather-the-storm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fear: file(
        relativePath: {
          eq: "ghost-brigade-until-fear-no-longer-defines-us.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2011}>
      <YearOverview>
        <p>
          Took a whole year break from touring after the madness of the previous
          years, playing only two acoustic <strong>Swallow the Sun</strong>{" "}
          shows in Finland.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Insomnium"
          name="One for Sorrow"
          published="Century Media"
          image={data.sorrow?.childImageSharp?.fluid}
        >
          <p>
            <i>Insomnium</i>’s fifth album, <i>One for Sorrow</i>, was business
            as usual&mdash;I arranged and performed all the keyboards and
            recorded and engineered them at Noisework Studio in Helsinki.
            Meanwhile, the album was mixed by Samu Oittinen at Fantom Studio,
            Tampere.
          </p>
        </Album>
        <Quote author="Andy Synn" source="No Clean Singing">
          <p>
            More than ever, the keyboards on this album are being treated as an
            instrument to be considered on a par with the rest, their
            contributions <span className={styles.textMuted}>[…]</span>{" "}
            providing depth and subtlety in their glimmering orchestral tones.
          </p>
        </Quote>
        <SideAlbum
          band="Insomnium"
          name="Weather the Storm"
          published="Century Media"
          image={data.weather?.childImageSharp?.fluid}
        >
          <p>
            To promote an upcoming <i>Insomnium</i> tour, we released a digital
            single <i>Weather the Storm</i> featuring Mikael Stanne from{" "}
            <i>Dark Tranquillity</i>.
          </p>
        </SideAlbum>
      </Page>
      <Page className={classNames(styles.center, styles.marginVertical5)}>
        <Album
          band="Ghost Brigade"
          name="Until Fear No Longer Defines Us"
          published="Season of Mist"
          image={data.fear?.childImageSharp?.fluid}
        >
          <p>
            Ghost Brigade’s third album, <i>Until Fear No Longer Defines Us</i>,
            was professionally one of the hardest albums for me to do&mdash;we
            had already decided that I wouldn’t tour with them and would
            eventually depart the band, and they didn’t want to use backing
            tracks live.
          </p>
          <p>
            As such, all the keyboards needed to be arranged and produced so,
            that they would elevate the album, but lacking them wouldn’t affect
            the live performance negatively. This taught me a <i>lot</i> about
            writing pads and soundscapes. The album was recorded by Antti
            Malinen at Seawolf Studios, Helsinki, while I recorded most of my
            parts at home.
          </p>
        </Album>
        <Quote author="Andy Synn" source="No Clean Singing">
          <p>
            Befitting its beautiful artwork, the album has a full and evocative
            sound; lush, melancholy melodies shimmering like starlight atop
            mountains of heaving, Neurosis-esque riffage, while subtle keyboard
            embellishments provide unobtrusive, yet unashamedly progressive
            undercurrents.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2011
