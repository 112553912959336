import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2015: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2015Query>(graphql`
    query Year2015 {
      songs: file(
        relativePath: { eq: "swallow-the-sun-songs-from-the-north.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sea: file(relativePath: { eq: "insomnium-out-to-the-sea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2015} dark>
      <YearOverview shows={34} countries={15} dark>
        <p>
          We started the <strong>Swallow the Sun</strong> tour cycle for{" "}
          <strong>Songs from the North</strong> with some headlining and
          festival shows in Finland and rest of Europe.
        </p>
      </YearOverview>
      <Page className={styles.center}>
        <Album
          band="Swallow the Sun"
          name="Songs from the North"
          published="Century Media"
          image={data.songs?.childImageSharp?.fluid}
        >
          <p>
            While working on <i>Swallow the Sun</i> sixth album{" "}
            <i>Songs from the North</i> I had no idea that it would end up being
            my last one with the band. The decision to quit in 2016, however,
            was so much easier after accomplishing something like this&mdash;
            <i>Songs from the North</i> is a monumental concept and could have
            gone wrong in so many ways, but us being able to pull off a
            triple-album, a representation of all of the musical extremes that
            define <i>Swallow the Sun</i> was not a small feat, and I couldn’t
            be happier about it. I feel it perfectly wraps up my time in the
            band.
          </p>
          <p>
            I recorded and engineered all of the keyboards at Hannu Honkonen’s
            workshop over five sweaty days. Everything else was recorded and
            mixed all over the place by Hiili Hiilesmaa, Juha Heininen, Tuomas
            Kokko and Jaani Peuhu, who also produced the vocals.
          </p>
          <p>
            I also did the art direction and layouts for the album based on the
            photography of Aleah Stanbridge, graphical elements by Rami Mursula
            and my own photography&mdash;I shot most of the booklet photos in
            Poland and Finland over the summer.
          </p>
        </Album>

        <Quote
          author="Dom Lawson"
          source="Metal Hammer"
          style={{ marginBottom: 0 }}
        >
          <p>
            Songs From The North is a stunning, near-flawless achievement and
            the perfect soundtrack for bleak winters, both real and ethereal.
          </p>
        </Quote>
        <Quote author="Thom Jurek" source="All Music" right>
          <p>
            Releasing Songs from the North I, II & III demonstrates a radical
            belief in the physical concept of the album&mdash;an act of defiance
            in this age. While each disc stands on its own, it’s the sum total
            that makes this such a career-defining work.
          </p>
        </Quote>
      </Page>
      <Page className={styles.center} height={PageHeight.Half}>
        <SideAlbum
          band="Insomnium"
          name="Out to the Sea"
          published="Century Media"
          image={data.sea?.childImageSharp?.fluid}
        >
          <p>
            Split 7&Prime; vinyl with Omnium Gatherum of <i>Out to the Sea</i>,
            a bonus track from <i>Shadows of the Dying Sun</i>.
          </p>
        </SideAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2015
