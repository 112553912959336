import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2002: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2002Query>(graphql`
    query Year2002 {
      code666: file(relativePath: { eq: "funeris-nocturnum-code-666.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2002} dark>
      <YearOverview dark shows={23} bands={2}>
        <p>
          Played a bunch of shows in Finland between 1999 and 2002 with{" "}
          <strong>Funeris Nocturnum</strong> and{" "}
          <strong>Trollheim’s Grott</strong>. My memories of these are hazy at
          best.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          className={styles.marginVertical5}
          band="Funeris Nocturnum"
          name="Code 666: Religion Syndrome Deceased"
          published="Woodcut Records"
          image={data.code666?.childImageSharp?.fluid}
        >
          <p>
            Our third album, <i>Code 666: Religion Syndrome Deceased</i> is
            slightly slower and more melodic than the previous ones, and we
            started experimenting with electronic stuff&mdash;the album even
            features an unnamed hidden track, a proper teeth-grinding techno
            banger.
          </p>
          <p>
            I played and arranged the keyboards, wrote and produced the hidden
            track, and wrote the music and lyrics for the songs{" "}
            <i>The Sculptor</i> and <i>The Walls Breed Larvae</i>. Recorded with
            Arttu Sarvanne at Studio Watercastle in Jyväskylä.
          </p>
        </Album>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2002
