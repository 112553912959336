import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2009: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2009Query>(graphql`
    query Year2009 {
      moon: file(relativePath: { eq: "swallow-the-sun-new-moon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      across: file(relativePath: { eq: "insomnium-across-the-dark.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      isolation: file(
        relativePath: { eq: "ghost-brigade-isolation-songs.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2009}>
      <YearOverview shows={140} countries={13} bands={3}>
        <p>
          <strong>Swallow the Sun</strong> played a massive tour with{" "}
          <strong>Soilwork</strong> in North America, as well as, a
          co-headlining European tour with <strong>Insomnium</strong>, and a
          bunch of other shows. In addition, I played with{" "}
          <strong>Insomnium</strong> and <strong>Ghost Brigade</strong>{" "}
          following the releases of their albums in Finland and Europe.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Swallow the Sun"
          name="New Moon"
          published="Spinefarm Records"
          image={data.moon?.childImageSharp?.fluid}
        >
          <p>
            With <i>New Moon</i> we set our sights to the United
            States&mdash;following its release we played some hundred or so
            shows all around the States. I engineered and recorded my parts at
            Noisework Studio, while the rest of the album was recorded with Jens
            Bogren at Fascination Street in Örebro, Sweden.
          </p>
          <p>
            In addition to the keyboard work, I also did the art direction and
            layouts for the album in co-operation with Rami Mursula, who
            provided the beautiful wood-cut style illustrations.
          </p>
        </Album>
        <Quote author="KwonVerge" source="Metal Storm">
          <p>
            Huge amounts of caressing melody, heavier moments, outbursts in rage
            and slow-paced atmospheric passages, all of them blending together
            in utter harmony. The keyboard lines float in a devout or
            threatening way overshadowing the sun while the guitars paint the
            soundscape with scarlet and grey colours with their acoustic,
            melodic or heavier dance.
          </p>
        </Quote>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Insomnium"
          name="Across the Dark"
          published="Candlelight Records"
          image={data.across?.childImageSharp?.fluid}
        >
          <p>
            While <i>Insomnium</i>’s third album, <i>Above the Weeping World</i>
            , featured keyboards only on some of the songs, they took a more
            prominent role on <i>Across the Dark</i>. I recorded and engineered
            my keyboards with Hannu Honkonen at Noisework Studio in Helsinki.
            The album was mixed and recorded by Samu Oittinen at Fantom Studio,
            Tampere.
          </p>
        </Album>
        <Quote author="Erik Thomas" source="Last Rites">
          <p>
            Insomnium have elevated their song writing skills from the already
            stunning back catalog and dropped an album full of such knee
            wilting, somber melody and lush atmospheres, it literally cries for
            your rapt attention and most certainly will be atop many critics’
            top ten lists for 2009.
          </p>
        </Quote>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Ghost Brigade"
          name="Isolation Songs"
          published="Season of Mist"
          image={data.isolation?.childImageSharp?.fluid}
        >
          <p>
            I joined <i>Ghost Brigade</i> as a full-time member for their second
            album <i>Isolation Songs</i>, and it remains one of my favourite
            albums I’ve ever worked on. I recorded my parts of the album mostly
            at home and Lutakko, while the rest of it was recorded by Antti
            Malinen at Seawolf Studios, Helsinki. Funnily enough, our paths with
            Antti would cross later on co-founding the software company{" "}
            <i>Sangre</i>.
          </p>
        </Album>
        <Quote source="Rock Sound">
          <p>
            Ghost Brigade specialise in precision riffs that frame dense musical
            structures, segueing from clinical brutality to melodic beauty in
            easily digestible form, this follow up to the critically acclaimed
            ‘Guided By Fire’ is further proof of Finland's perpetual musical
            supremacy.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2009
