import React from "react"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import styles from "./Quote.module.scss"
import { useAnimateOnce } from "../../theme/useAnimateOnce"
import { useDOMPath } from "../../theme/useDOMPath"

interface QuoteProps extends React.HTMLAttributes<HTMLQuoteElement> {
  author?: string
  source: string
  right?: boolean
}

const Quote: React.FC<QuoteProps> = ({
  children,
  author,
  source,
  className,
  right,
  ...rest
}) => {
  const [ref, visible, entry] = useInView({
    rootMargin: "-10% 0px",
    triggerOnce: true,
  })

  const displayId = useDOMPath(entry?.target)
  const inView = useAnimateOnce(displayId, visible)

  return (
    <blockquote
      className={classNames(
        styles.container,
        inView,
        right && styles.right,
        className
      )}
      ref={ref}
      {...rest}
    >
      {children}
      <footer className={classNames(styles.footer, inView)}>
        {author}
        <cite className={classNames(styles.cite, author && styles.withAuthor)}>
          {source}
        </cite>
      </footer>
    </blockquote>
  )
}

export default Quote
