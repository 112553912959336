import React from "react"
import Page, { PageHeight } from "../components/Page"
import Layout from "../components/Layout"
import SiteMeta from "../components/SiteMeta"
import commonStyles from "./pages.module.scss"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "../components/BackgroundImage"

import TimelineSection2000 from "../components/TimelineSection/TimelineSection2000"
import TimelineSection2001 from "../components/TimelineSection/TimelineSection2001"
import TimelineSection2002 from "../components/TimelineSection/TimelineSection2002"
import TimelineSection2003 from "../components/TimelineSection/TimelineSection2003"
import TimelineSection2004 from "../components/TimelineSection/TimelineSection2004"
import TimelineSection2005 from "../components/TimelineSection/TimelineSection2005"
import TimelineSection2006 from "../components/TimelineSection/TimelineSection2006"
import TimelineSection2007 from "../components/TimelineSection/TimelineSection2007"
import TimelineSection2008 from "../components/TimelineSection/TimelineSection2008"
import TimelineSection2009 from "../components/TimelineSection/TimelineSection2009"
import TimelineSection2010 from "../components/TimelineSection/TimelineSection2010"
import TimelineSection2011 from "../components/TimelineSection/TimelineSection2011"
import TimelineSection2012 from "../components/TimelineSection/TimelineSection2012"
import TimelineSection2013 from "../components/TimelineSection/TimelineSection2013"
import TimelineSection2014 from "../components/TimelineSection/TimelineSection2014"
import TimelineSection2015 from "../components/TimelineSection/TimelineSection2015"
import TimelineSection2016 from "../components/TimelineSection/TimelineSection2016"
import TimelineSection2017 from "../components/TimelineSection/TimelineSection2017"
import TimelineSection2018 from "../components/TimelineSection/TimelineSection2018"
import TimelineSection2019 from "../components/TimelineSection/TimelineSection2019"
import classNames from "classnames"

const Music: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.MusicQuery>(graphql`
    query Music {
      image: file(relativePath: { eq: "electronic-keyboard-1867120.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ogImage: file(relativePath: { eq: "og-image-music.png" }) {
        childImageSharp {
          fixed(quality: 60, width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SiteMeta
        title={`Music`}
        description="I’m a musician, worked on 25+ internationally released albums as a performer, arranger, producer, graphic designer and composer, with extensive experience in music and touring business."
        cardImage={data?.ogImage?.childImageSharp?.fixed}
      />
      <Page
        height={PageHeight.TwoThirds}
        className={commonStyles.headerWrapper}
      >
        <div
          className={classNames(
            commonStyles.container,
            commonStyles.headerContainer
          )}
        >
          <h1>Music</h1>
          <p className={commonStyles.lead}>
            Since my first album release in 2000, I’ve worked extensively with
            multiple bands, providing keyboard arrangements, production,
            engineering, graphic design and art direction, and I have thorough
            experience in touring and tour production. Below you will find an
            unabridged timeline of my works so far, but I am always open to new
            opportunities&mdash;feel free to{" "}
            <Link to="/contact">contact me</Link>.
          </p>
        </div>
        <BackgroundImage
          className={commonStyles.headerImage}
          displayId="music-header"
          fluid={data.image?.childImageSharp?.fluid}
        />
      </Page>

      <TimelineSection2019 />
      <TimelineSection2018 />
      <TimelineSection2017 />
      <TimelineSection2016 />
      <TimelineSection2015 />
      <TimelineSection2014 />
      <TimelineSection2013 />
      <TimelineSection2012 />
      <TimelineSection2011 />
      <TimelineSection2010 />
      <TimelineSection2009 />
      <TimelineSection2008 />
      <TimelineSection2007 />
      <TimelineSection2006 />
      <TimelineSection2005 />
      <TimelineSection2004 />
      <TimelineSection2003 />
      <TimelineSection2002 />
      <TimelineSection2001 />
      <TimelineSection2000 />
    </Layout>
  )
}

export default Music
