import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2006: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2006Query>(graphql`
    query Year2006 {
      above: file(
        relativePath: { eq: "insomnium-above-the-weeping-world.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2006} dark>
      <YearOverview shows={28} countries={7} bands={4} dark>
        <p>
          In addition to bunch of festival shows, we also played our first short
          tour abroad with <strong>Swallow the Sun</strong>. I also played my
          first ever shows with <strong>Insomnium</strong> and{" "}
          <strong>Ghost Brigade</strong>.
        </p>
      </YearOverview>
      <Page className={classNames(styles.center, styles.marginVertical5)}>
        <Album
          band="Insomnium"
          name="Above the Weeping World"
          published="Candlelight Records"
          image={data.above?.childImageSharp?.fluid}
        >
          <p>
            After working on a few songs on their second album, <i>Insomnium</i>{" "}
            asked me to do all of the keyboard arrangements for their third one,{" "}
            <i>Above the Weeping World</i>. As the keyboards took a bigger role
            in their music, I also started playing live shows with them.
          </p>
          <p>
            I arranged and performed the keyboards for the album, recorded by
            Samu Oittinen at Fantom Studio, Tampere.
          </p>
        </Album>
        <Quote author="Lauryn Mercer" source="Metal Injection">
          <p>
            Insomnium are some of the best in the business at crafting memorable
            melodic death metal, and Above The Weeping World is by far one of
            their best works.
          </p>
        </Quote>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2006
