import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2004: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2004Query>(graphql`
    query Year2004 {
      since: file(
        relativePath: { eq: "insomnium-since-the-day-it-all-came-down.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2004} dark>
      <YearOverview shows={28} countries={2} bands={4} dark>
        <p>
          Played my last shows with <strong>Funeris Nocturnum</strong>,
          supporting <strong>Zyklon</strong> in Finland.{" "}
          <strong>Swallow the Sun</strong>’s first show abroad in Estonia and
          lots of summer festivals in Finland.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <SideAlbum
          band="Insomnium"
          name="Since The Day It All Came Down"
          published="Candlelight Records"
          image={data.since?.childImageSharp?.fluid}
        >
          <p>
            I arranged the keyboards for two songs on <i>Insomnium</i>’s second
            album, <i>Under the Plaintive Sky</i> and{" "}
            <i>Song of the Forlorn Son</i>.
          </p>
        </SideAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2004
