import React from "react"
import classNames from "classnames"
import { TimelineSectionProps } from "./types"
import styles from "./TimelineSection.module.scss"
import BackgroundImage from "../BackgroundImage"

const TimelineSection: React.FC<TimelineSectionProps> = ({
  year,
  dark = false,
  children,
  className,
  backgroundImage,
}) => (
  <div className={classNames(className, styles.container, dark && styles.dark)}>
    {backgroundImage && (
      <BackgroundImage
        dark={dark}
        gradientTop
        gradientBottom
        fluid={backgroundImage}
      />
    )}
    <div className={styles.wrapper}>
      <div className={classNames(styles.timeline)}>
        <h3 className={styles.year}>{year}</h3>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)

export default TimelineSection
