import React from "react"
import { SubAlbumProps } from "./types"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import Image from "../Image"
import styles from "./SubAlbum.module.scss"
import { useAnimateOnce } from "../../theme/useAnimateOnce"

const SubAlbum: React.FC<SubAlbumProps> = ({
  className,
  band,
  name,
  image,
  children,
}) => {
  const [ref, visible] = useInView({
    rootMargin: "-10% 0px",
    triggerOnce: true,
  })

  const displayId = `${band || "sub-album"}-${name}`
  const inView = useAnimateOnce(displayId, visible)

  return (
    <div className={classNames(styles.album, className)} ref={ref}>
      <div className={styles.imageContainer}>
        {image && (
          <Image
            fluid={image}
            visible={visible}
            className={styles.image}
            displayId={displayId}
          />
        )}
      </div>
      <div className={classNames(styles.info, inView)}>
        <h5 className={classNames(styles.title)}>
          {band && (
            <>
              {band} <span className={styles.separator}> | </span>
            </>
          )}
          <span className={styles.name}>{name}</span>
        </h5>
        {children}
      </div>
    </div>
  )
}

export default SubAlbum
