import React from "react"
import TimelineSection from "./TimelineSection"
import { PageHeight } from "../Page"
import { graphql, useStaticQuery } from "gatsby"
import YearOverview from "../YearOverview"

const TimelineSection2013: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2013Query>(graphql`
    query Year2013 {
      image: file(relativePath: { eq: "background-2017.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection
      year={2013}
      backgroundImage={data.image?.childImageSharp?.fluid}
      dark
    >
      <YearOverview height={PageHeight.Third} shows={24} countries={9} dark>
        <p>
          We did a <strong>Swallow the Sun</strong> headlining tour for the 10th
          anniversary of <strong>The Morning Never Came</strong> in Finland and
          Europe.
        </p>
      </YearOverview>
    </TimelineSection>
  )
}

export default TimelineSection2013
