import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import SideAlbum from "../SideAlbum"
import Album from "../Album"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"

const TimelineSection2001: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2001Query>(graphql`
    query Year2001 {
      darklyIlluminated: file(
        relativePath: {
          eq: "funeris-nocturnum-from-the-aspect-of-darkly-illuminated.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slayAndBurn: file(
        relativePath: { eq: "funeris-nocturnum-slay-and-burn.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trollTechnology: file(
        relativePath: { eq: "trollheims-grott-bizarre-troll-technology.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2001} dark>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Full}
      >
        <Album
          className={styles.marginVertical5}
          band="Funeris Nocturnum"
          name="From the Aspect of Darkly Illuminated"
          published="Woodcut Records"
          image={data.darklyIlluminated?.childImageSharp?.fluid}
        >
          <p>
            On our second album <i>From the Aspect of Darkly Illuminated</i> we
            veered towards more of a death metal approach from the first album’s
            straightforward symphonic black metal, but did not give up on speed.
          </p>
          <p>
            In addition to playing and arranging the keys, I wrote the album
            starter <i>The Betrayal, the Betrayed and an Act of Blasphemy</i>{" "}
            and the interlude <i>Nameless</i>, featuring some peak 90’s string
            instrument samples. Recorded with Arttu Sarvanne at Studio
            Watercastle, Jyväskylä.
          </p>
        </Album>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Full}
      >
        <SideAlbum
          band="Funeris Nocturnum"
          name="Slay and Burn"
          published="Woodcut Records"
          image={data.slayAndBurn?.childImageSharp?.fluid}
        >
          <p>
            We released the <strong>Funeris Nocturnum</strong> demo{" "}
            <i>Slay and Burn</i> as an EP, with some bonus alcohol-fuelled cover
            songs. This one features the other guitar solo I’ve ever recorded on
            our cover of <i>Manowar</i>’s <i>Wheels of Fire</i>.
          </p>
        </SideAlbum>
        <SideAlbum
          band="Trollheim’s Grott"
          name="Bizarre Troll Technology"
          published="Woodcut Records"
          image={data.trollTechnology?.childImageSharp?.fluid}
        >
          <p>
            I co-wrote and produced the electronic track <i>Chased by Trolls</i>{" "}
            on <strong>Trollheim’s Grott</strong> first album{" "}
            <i>Bizarre Troll Technology</i>.
          </p>
        </SideAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2001
