import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import SideAlbum from "../SideAlbum"
import SubAlbum from "../SubAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2005: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2005Query>(graphql`
    query Year2005 {
      ghosts: file(relativePath: { eq: "swallow-the-sun-ghosts-of-loss.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forgive: file(relativePath: { eq: "swallow-the-sun-forgive-her.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      falling: file(relativePath: { eq: "machine-men-falling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      elegies: file(relativePath: { eq: "machine-men-elegies.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      atakhama: file(
        relativePath: { eq: "atakhama-existence-indifferent.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hmp: file(
        relativePath: { eq: "heavy-metal-perse-tervemenoa-tuonelaan.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2005}>
      <YearOverview shows={23} bands={2}>
        <p>
          Played a bunch of festivals and other shows in Finland with{" "}
          <strong>Swallow the Sun</strong> and{" "}
          <strong>Trollheim’s Grott</strong>.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <Album
          band="Swallow the Sun"
          name="Ghosts of Loss"
          published="Firebox Records"
          image={data.ghosts?.childImageSharp?.fluid}
        >
          <p>
            The second <i>Swallow the Sun</i> album turned out quite a bit more
            progressive than the first one. I performed and arranged all the
            keyboards, as well as, wrote the music and lyrics for <i>Fragile</i>
            . <i>Ghosts of Loss</i> was recorded with Sami Kokko at Sam’s
            Workshop in Jyväskylä.
          </p>
        </Album>
        <Quote author="Patrick Dawson" source="Last Rites">
          <p>
            Layered keyboards, viscid, expansive guitar tone and a vocalist at
            ease with this mild rumbling growl are the veneer that allows a
            shine to surface from deep within a creation built on the legacy of
            My Dying Bride’s percussive march and the influence of contemporary
            genre blenders like Morgion. This time around, the time-honoured
            standard doom checklist is expanded upon with variation and a
            surging passion yanked from the more chaotic metal of death.
          </p>
        </Quote>
        <SideAlbum
          band="Swallow the Sun"
          name="Forgive Her&hellip;"
          published="Firebox Records"
          image={data.forgive?.childImageSharp?.fluid}
        >
          <p>
            The single release from <i>Ghosts of Loss</i> included a cover{" "}
            <i>Candlemass</i>’s <i>Solitude</i> featuring Albert Witchfinder
            from <i>Reverend Bizarre</i>.
          </p>
        </SideAlbum>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <SubAlbum
          className={styles.mbSubAlbum}
          name="Machine Men"
          image={data.elegies?.childImageSharp?.fluid}
        >
          <p>
            On the second <i>Machine Men</i> album&mdash;<i>Elegies</i>&mdash;I
            arranged the cello parts, and wrote and performed the keyboards for
            the song <i>From Sunrise to Sunset</i>. Released by Century Media.
          </p>
        </SubAlbum>
        <SubAlbum
          className={styles.mbSubAlbum}
          name="Machine Men"
          image={data.falling?.childImageSharp?.fluid}
        >
          <p>
            On the single release from <i>Elegies</i>, <i>Falling</i>, I
            produced an electronic remix of the song <i>Dream &amp; Religion</i>
            .
          </p>
        </SubAlbum>
        <SubAlbum
          className={styles.mbSubAlbum}
          name="Atakhama"
          image={data.atakhama?.childImageSharp?.fluid}
        >
          <p>
            Some of the unreleased songs I wrote for <i>Funeris Nocturnum</i>{" "}
            were rearranged for <i>Atakhama</i> album{" "}
            <i>Existence Indifferent</i> to fit the death metal aesthetic of the
            band. Released by Woodcut Records.
          </p>
        </SubAlbum>
        <SubAlbum
          name="Heavy Metal Perse"
          image={data.hmp?.childImageSharp?.fluid}
        >
          <p>
            I arranged and recorded the keyboards for <i>Heavy Metal Perse</i>{" "}
            self-released three-song EP, <i>Tervemenoa Tuonelaan!</i>
          </p>
        </SubAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2005
