import React from "react"
import TimelineSection from "./TimelineSection"
import Page from "../Page"
import Album from "../Album"
import Quote from "../Quote"
import SideAlbum from "../SideAlbum"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./TimelineSection.module.scss"

/*
 Pantheon of the Nightside Gods
 There is absolute nothing here

 Belzebubs
 Secretive

 Century Media
 Not revealing
 */

const TimelineSection2019: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2019Query>(graphql`
    query Year2019 {
      grave: file(relativePath: { eq: "insomnium-heart-like-a-grave.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      starkill: file(relativePath: { eq: "starkill-gravity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2019}>
      <Page className={styles.center}>
        <Album
          band="Insomnium"
          name="Heart like a Grave"
          published="Century Media"
          image={data.grave?.childImageSharp?.fluid}
        >
          <p>
            <i>Insomnium</i>’s eight album, <i>Heart Like a Grave</i> was a
            great follow up to <i>Winter’s Gate</i>’s success. I had taken a bit
            of a break from music after quitting <i>Swallow the Sun</i>, so
            getting back to recording was exciting. Once again, I did all the
            keyboard arrangements, recording and production over the spring at
            Hannu Honkonen’s workshop in Helsinki. The album was engineered by
            Jens Bogren at Fascination Street Studio in Örebro, Sweden.
          </p>
        </Album>
        <Quote author="Jordan Blum" source="Metal Injection">
          <p>
            Starter “Wall of the North” works as both one of the best instances
            of that juxtaposition and one of the best tracks on the LP overall.
            Its immediate subtle tragedy is downright breath-taking, as mournful
            piano notes and chords cascade around grief-stricken strings until
            harsher timbres—percussion, electric guitars, keyboards, growled
            verses, etc.—build upon that foundation.
          </p>
        </Quote>
      </Page>
      <SideAlbum
        band="Starkill"
        name="Gravity"
        published="Independent"
        image={data.starkill?.childImageSharp?.fluid}
      >
        <p>
          I helped the melodic metal band from Chicago with the keyboard
          arrangements of their self-published album.
        </p>
      </SideAlbum>
    </TimelineSection>
  )
}

export default TimelineSection2019
