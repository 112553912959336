import React from "react"
import { SideAlbumProps } from "./types"
import classNames from "classnames"
import { useInView } from "react-intersection-observer"
import Image from "../Image"
import styles from "./SideAlbum.module.scss"
import { useAnimateOnce } from "../../theme/useAnimateOnce"

const SideAlbum: React.FC<SideAlbumProps> = ({
  className,
  band,
  name,
  published,
  image,
  children,
}) => {
  const [ref, visible] = useInView({
    rootMargin: "-10% 0px",
    triggerOnce: true,
  })

  const displayId = `${band}-${name}`
  const inView = useAnimateOnce(displayId, visible)

  return (
    <div className={classNames(styles.album, className)} ref={ref}>
      <div className={styles.imageContainer}>
        {image && (
          <Image
            fluid={image}
            visible={visible}
            className={styles.image}
            displayId={displayId}
          />
        )}
      </div>

      <div className={classNames(styles.info, inView)}>
        <h5
          className={classNames(styles.title, published && styles.hasPublished)}
        >
          {band}
          <span className={styles.separator}> | </span>
          <span className={styles.name}>{name}</span>
        </h5>
        {published && <p className={styles.published}>{published}</p>}
        {children}
      </div>
    </div>
  )
}

export default SideAlbum
