import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import Album from "../Album"
import SubAlbum from "../SubAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2008: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2008Query>(graphql`
    query Year2008 {
      plague: file(
        relativePath: { eq: "swallow-the-sun-plague-of-butterflies.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chaosweaver: file(
        relativePath: { eq: "chaosweaver-puppetmaster-of-pandemonium.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hmp: file(relativePath: { eq: "heavy-metal-perse-eripura.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2008} dark>
      <YearOverview shows={39} countries={7} bands={3} dark>
        <p>
          <strong>Swallow the Sun</strong> played a UK tour supporting{" "}
          <strong>Apocalyptica</strong>, and I played some shows in Europe and
          Russia with <strong>Swallow the Sun</strong>,{" "}
          <strong>Ghost Brigade</strong> and <strong>Insomnium</strong>.
        </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.TwoThirds}
      >
        <Album
          band="Swallow the Sun"
          name="Plague of Butterflies"
          published="Spinefarm Records"
          image={data.plague?.childImageSharp?.fluid}
        >
          <p>
            <i>Plague of Butterflies</i> was our first venture into
            non-traditional release formats. Clocking in at 35 minutes, the
            single song album was originally written to be performed by New
            York-based <i>Ballet Deviare</i>. The album&mdash;or
            EP&mdash;included our first demo <i>Out of this Gloomy Light</i>,
            which was never released to the public: only 2 or so physical copies
            were ever made.
          </p>
          <p>
            This was also my first time working with visual arts: I did the
            layout of the album booklet and covers based on the paintings of
            Jyväskylä-based artist Minja Revonkorpi. And again, I recorded and
            engineered my keyboards again with Hannu Honkonen at his place,
            while the album was recorded and mixed by Sami Koivisto, Biotech
            Audio Solutions.
          </p>
        </Album>
      </Page>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <SubAlbum
          className={styles.mbSubAlbum}
          name="Chaosweaver"
          image={data.chaosweaver?.childImageSharp?.fluid}
        >
          <p>
            I did a some of the keyboard arrangements for the song{" "}
            <i>White Noise</i> on <i>Chaosweaver</i>’s album{" "}
            <i>Puppetmaster of Pandemonium</i>, released by Shadow World.
          </p>
        </SubAlbum>
        <SubAlbum
          name="Heavy Metal Perse"
          image={data.hmp?.childImageSharp?.fluid}
        >
          <p>
            I arranged and recorded six songs on <i>Heavy Metal Perse</i> album,{" "}
            <i>Eripura</i>, released by Dethrone Music.
          </p>
        </SubAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2008
