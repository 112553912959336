import React from "react"
import TimelineSection from "./TimelineSection"
import Page, { PageHeight } from "../Page"
import SubAlbum from "../SubAlbum"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import styles from "./TimelineSection.module.scss"
import YearOverview from "../YearOverview"

const TimelineSection2010: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.Year2010Query>(graphql`
    query Year2010 {
      katra: file(relativePath: { eq: "katra-out-of-the-ashes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hmp: file(relativePath: { eq: "heavy-metal-perse-hornan-koje.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TimelineSection year={2010} dark>
      <YearOverview shows={130} countries={21} bands={3} dark>
          <p>
            The heavy touring of 2009 continued with{" "}
            <strong>Swallow the Sun</strong> supporting{" "}
            <strong>Katatonia</strong> on their European and North American
            tours, <strong>Finntroll</strong> in North America, and headlining
            and festival shows in Europe and Israel. In addition, I played a
            bunch of shows with <strong>Insomnium</strong> and{" "}
            <strong>Ghost Brigade</strong>.
          </p>
      </YearOverview>
      <Page
        className={classNames(styles.center, styles.marginVertical5)}
        height={PageHeight.Half}
      >
        <SubAlbum
          className={styles.mbSubAlbum}
          name="Katra"
          image={data.katra?.childImageSharp?.fluid}
        >
          <p>
            I did keyboard arrangements and production for the Tampere-based
            singer <i>Katra</i>’s album <i>Out of the Ashes</i>, released by
            Napalm Records.
          </p>
        </SubAlbum>
        <SubAlbum
          name="Heavy Metal Perse"
          image={data.hmp?.childImageSharp?.fluid}
        >
          <p>
            <i>Heavy Metal Perse</i> released a 7&prime; single,{" "}
            <i>Hornan Koje</i>, through Dethrone Music&mdash;I did the keyboard
            arrangements and production on its B-side track,{" "}
            <i>Ja ylitse vihaisen meren</i>.
          </p>
        </SubAlbum>
      </Page>
    </TimelineSection>
  )
}

export default TimelineSection2010
